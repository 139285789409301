<script setup>
import { onMounted, ref, reactive } from 'vue';
import Layout from '@/Layouts/Layout.vue';
import ProgressBar from '@/Components/ProgressBar.vue';
import Heading1 from '@/Components/Heading1.vue';
import Heading2 from '@/Components/Heading1.vue';
import ButtonStd from '@/Components/ButtonStd.vue';
import Text from '@/Components/Text.vue';
import Note from '@/Components/Note.vue';
import { usePage } from '@inertiajs/vue3';
import Search from '@/Components/Search.vue';
import SectionBorder from '@/Components/SectionBorder.vue';
import Tile from '@/Pages/Home/Partials/Tile.vue';


const props = defineProps({ 
    
});

// Progressbar reference
const progress = ref();

onMounted(() => {
    progress.value.hide();
})

const go = (href) => {
    window.location.href = href;
}

const state = reactive({ 
    text: "",           // Search text
 }); 

/**
 * Redirect to explore view
 */
 const search = () => {
    window.location.href = "/set/explore/" + encodeURIComponent(state.text);
}

</script>

<template>
    <Layout :title="'home'" :search="false" :navBar="false">
        <template #header>
            <Text :text="'home'"/>
        </template>
 
        <div class="flex flex-col gap-4">

            <!--div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div class="flex justify-center items-center">
                    <div class="relative dark:bg-gray-900">
                        <img src="/asset/media/logo/logo-s.svg" alt="Logo icon" class="w-48 h-48 rounded-full border-4 border-white dark:border-gray-700">
                        <div class="absolute inset-0 rounded-full border-4 border-white dark:border-gray-700" style="filter: blur(8px);"></div>
                     </div>
                </div>
                <div class="flex items-center justify-center">
                    <div class="flex flex-col gap-3 items-center">
                        <Heading1 :text="'auth.title'"/>
                        <Heading2 :text="'auth.join_now'"/>
                        <div v-if="usePage().props.auth.user" class="flex gap-2">
                            <ButtonStd :active="true" @click="go('/set/library')" :label="'set.library'"/> 
                            <ButtonStd :active="true" @click="go('/report/show')" :label="'activity'"/> 
                        </div>
                        <div v-else class="flex gap-2">
                            <ButtonStd :active="true" @click="go('/login')" :label="'auth.login'"/> 
                            <ButtonStd :active="true" @click="go('/register')" :label="'auth.register'"/> 
                        </div>
                        <Note :text="'home.text1'"/> 
                    </div>
                </div>
            </div>

            <SectionBorder/>

            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div class="flex items-center justify-center">
                    <div class="flex flex-col gap-3 items-center">
                        <Heading1 :text="'home.explore.title'"/>
                        <div class="w-full flex justify-start items-center">
                            <Search v-model="state.text" :placeholder="'set.explore.search'" :class="'w-full'" @search="search"/>
                        </div>
                        <Note :text="'home.explore.text'"/> 
                    </div>
                </div>
                <div class="flex justify-center items-center">
                    <div class="relative dark:bg-gray-900">
                        <img src="/asset/media/img/library.jfif" alt="Logo icon" class="w-48 h-48 rounded-full border-4 border-white dark:border-gray-700">
                        <div class="absolute inset-0 rounded-full border-4 border-white dark:border-gray-700" style="filter: blur(8px);"></div>
                     </div>
                </div>
            </div>

            <SectionBorder/>

            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div class="flex justify-center items-center">
                    <div class="relative dark:bg-gray-900">
                        <img src="/asset/media/img/classroom.jfif" alt="Logo icon" class="w-48 h-48 rounded-full border-4 border-white dark:border-gray-800">
                        <div class="absolute inset-0 rounded-full border-4 border-white dark:border-gray-700" style="filter: blur(8px);"></div>
                     </div>
                </div>
                <div class="flex items-center justify-center">
                    <div class="flex flex-col gap-2 items-center">
                        <Heading1 :text="'home.school.title'"/>
                        <div v-if="usePage().props.auth.user" class="flex gap-2">
                            <ButtonStd :active="true" @click="go('/set/library')" :label="'set.library'"/> 
                            <ButtonStd v-if="usePage().props.auth.user.role == 1" :active="true" @click="go('/school/show')" :label="'school'"/> 
                        </div>
                        <div v-else class="flex gap-2">
                            <ButtonStd :active="true" @click="go('/login')" :label="'auth.login'"/> 
                            <ButtonStd :active="true" @click="go('/register')" :label="'auth.register'"/> 
                        </div>
                        <Note v-if="usePage().props.auth.user.role != 1" :text="'home.school.not_teacher'"/> 
                        <Note :text="'home.school.text'"/> 
                    </div>
                </div>
            </div>

            <SectionBorder/-->

            <Tile :image="'/asset/media/logo/logo-s.svg'">
                <template #content>
                    <Heading1 :text="'auth.title'"/>
                    <Heading2 :text="'auth.join_now'"/>
                    <div v-if="usePage().props.auth.user" class="flex gap-2">
                        <ButtonStd :active="true" @click="go('/set/library')" :label="'set.library'"/> 
                        <ButtonStd :active="true" @click="go('/report/show')" :label="'activity'"/> 
                    </div>
                    <div v-else class="flex gap-2">
                        <ButtonStd :active="true" @click="go('/login')" :label="'auth.login'"/> 
                        <ButtonStd :active="true" @click="go('/register')" :label="'auth.register'"/> 
                    </div>
                    <Note :text="'home.text1'"/> 
                </template>
            </Tile>

            <SectionBorder/>

            <Tile :image="'/asset/media/img/library.jfif'">
                <template #content>
                    <Heading1 :text="'home.explore.title'"/>
                    <div class="w-full flex justify-start items-center">
                        <Search v-model="state.text" :placeholder="'set.explore.search'" :class="'w-full'" @search="search"/>
                    </div>
                    <Note :text="'home.explore.text'"/> 
                </template>
            </Tile>

            <SectionBorder/>

            <Tile :image="'/asset/media/img/classroom.jfif'">
                <template #content>
                    <Heading1 :text="'home.school.title'"/>
                    <div v-if="usePage().props.auth.user" class="flex gap-2">
                        <ButtonStd :active="true" @click="go('/set/library')" :label="'set.library'"/> 
                        <ButtonStd v-if="usePage().props.auth.user.role == 1" :active="true" @click="go('/school/show')" :label="'school'"/> 
                    </div>
                    <div v-else class="flex gap-2">
                        <ButtonStd :active="true" @click="go('/login')" :label="'auth.login'"/> 
                        <ButtonStd :active="true" @click="go('/register')" :label="'auth.register'"/> 
                    </div>
                    <Note v-if="usePage().props.auth.user.role != 1" :text="'home.school.not_teacher'"/> 
                    <Note :text="'home.school.text'"/> 
                </template>
            </Tile>


        </div>      
       
        <ProgressBar ref="progress" />
    </Layout>
</template>